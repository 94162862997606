import type {
  AuthOtpResponse,
  AuthTokenResponsePassword
} from '@supabase/auth-js'
import type { Database, Enums } from '~~/types/supabase'

export default function useAuth() {
  const supabase = useSupabaseClient<Database>()
  const emailRedirectTo = `${useRuntimeConfig().public.baseUrl}/confirm`

  // Initialize the state reference immediately
  const currentUserRole = useState<Enums<'app_role'> | null>(
    'rn_user_role',
    () => null
  )

  async function logout() {
    await supabase.auth.signOut()
    await navigateTo('/login')
  }

  async function sendMagicLink(
    email: string
  ): Promise<Omit<AuthOtpResponse, 'data'>> {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: false,
        emailRedirectTo
      }
    })

    return { error }
  }

  async function signInWithPassword(
    email: string,
    password: string
  ): Promise<Omit<AuthTokenResponsePassword, 'data'>> {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password
    })

    return { error }
  }

  return {
    currentUserRole,
    sendMagicLink,
    signInWithPassword,
    logout
  }
}
